// 引入
@import "./_include/_mixin.sass";
@import "./_include/_media.sass";

body.error404 {
  #site-content {
    background-color: #fff;
    padding: 100px 0;
    @media #{$phone-x} {
      padding: 50px 0; } } }

.breadcrumb-container {
  display: none; }

.container-404 {
  max-width: 722px;
  margin: 0px auto;
  padding: 90px 20px 60px;
  border-radius: 4px;
  width: calc(100% - 40px);
  border: 3px solid #AAAAAA;
  @media #{$phone-x} {
    padding: 50px 20px 40px; } }

.img-404 {
  max-width: 460px;
  margin: 0 auto 50px;
  position: relative;
  @media #{$phone-x} {
    margin-bottom: 40px; } }


.robot {
  position: absolute;
  z-index: 10;
  right: 50%;
  bottom: 8%;
  transform: translateX(50%);
  width: 9%;
  img {
    max-width: 100%;
    width: 100%;
    height: auto; }
  .red {
    position: absolute;
    bottom: 0;
    left: 0;
    animation: robot 0.5s infinite alternate; } }

.word-404 {
  p {
    @include cr(#AAAAAA);
    text-align: center;
    font-size: 16px;
    line-height: 25px;
    font-weight: bold;
    @media #{$phone-x} {
      font-size: 14px; } } }

@keyframes robot {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }


#go-home-links {
  display: table;
  padding: 22px 54px;
  margin: 60px auto 80px;
  border-radius: 50px;
  background-color: #979797;
  font-size: 16px;
  text-decoration: none;
  @include cr(#fff);
  @media #{$phone-x} {
    margin: 40px auto 50px;
    padding: 18px 40px;
    font-size: 14px; } }
